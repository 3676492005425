<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderMenuReportPanel
        :title="'TRANSACTION'"
        :filter="filter"
        :placeholder="'Search Invoice Number'"
        @sidebar="sidebarFilter"
        :btnFilter="true"
        :btnExport="true"
        @search="handleSearch"
        @confirmExport="getFilterExport"
        importPath="/report/transaction"
        @downLoadTemplate="downLoadTemplate"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableListReportCustomer
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :showing="showing"
          :showingTo="showingTo"
          :rows="rows"
          :pageOptions="pageOptions"
          :filter="filter"
          @page="filterPage"
          @handleChangeTake="handleChangeTake"
        />
      </div>
    </div>
    <SidebarFilterTransactionReport
      ref="SidebarFilterTransaction"
      :saleChanel="saleChanel"
      :statusFilter="statusFilter"
      :branch="branchList"
      :filter="filter"
      @submitFilter="submitFilter"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuReportPanel from "@/components/report/header/HeaderMenuReportPanel";
import TableListReportCustomer from "@/components/report/customer/TableListReportCustomer";
import SidebarFilterTransactionReport from "@/components/report/transaction/SidebarFilterTransactionReport";
import ModalLoading from "@/components/modal/ModalLoading";
export default {
  components: {
    OtherLoading,
    HeaderMenuReportPanel,
    TableListReportCustomer,
    SidebarFilterTransactionReport,
    ModalLoading,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        page: 1,
        take: 10,
        search: "",
        start_date: "",
        end_date: "",
        branch_id: 0,
        source_id: null,
        status_id: null,
      },
      fields: [
        {
          label: "Pos/Mim No.",
          key: "invoice_no",
          class: "w-150",
        },
        {
          label: "Member ID/Customer Name",
          key: "member_id",
          class: "w-150",
        },
        {
          label: "Days/Time Spent",
          key: "created_time",
          class: "w-150",
        },
        {
          label: "Grand Total",
          key: "grand_total",
          class: "w-100px",
        },
        {
          label: "Seller Name",
          key: "sale_name",
          class: "w-100px",
        },
        {
          label: "Branch Name",
          key: "branch_name",
          class: "w-100px",
        },
        {
          label: "Source Name",
          key: "sale_channel_name",
          class: "w-100px",
        },
        {
          label: "Status",
          key: "status_name",
        },
      ],
      items: [],
      isBusy: true,
      showing: 1,
      showingTo: 10,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchList: [],
      saleChanel: [
        { id: 2, name: "MIM" },
        { id: 1, name: "Booth" },
      ],
      statusFilter: [
        { id: 1, name: "Complate" },
        { id: 2, name: "Void/Returned" },
        { id: 4, name: "Deleted" },
      ],
      filterExport: {
        search: "",
        start_date: "",
        end_date: "",
        branch_id: 0,
        source_id: null,
        status_id: null,
      },
    };
  },
  async created() {
    await this.getBranchByFilter();
    await this.getTransactionList();
  },
  methods: {
    async getTransactionList() {
      this.isBusy = true;
      await this.$store.dispatch("getReportTransactionList", this.filter);
      const data = this.$store.state.report.stateReportTransactionList;
      if (data.result === 1) {
        this.items = data.detail.transaction_list;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    async getBranchByFilter() {
      await this.$store.dispatch("getBranchFilterTransaction");
      const data = this.$store.state.report.branchListReport;
      if (data.result == 1) {
        this.branchList = data.detail;
      }
      this.isLoading = false;
    },
    sidebarFilter() {
      this.$refs.SidebarFilterTransaction.show();
    },
    handleSearch(value) {
      this.filter = value;
      this.getTransactionList();
    },
    async getFilterExport() {
      this.$refs.modalLoading.show();
      this.filterExport.search = this.filter.search;
      this.filterExport.start_date = this.filter.start_date;
      this.filterExport.end_date = this.filter.end_date;
      this.filterExport.branch_id = this.filter.branch_id;
      this.filterExport.source_id = this.filter.source_id;
      this.filterExport.status_id = this.filter.status_id;
      await this.$store.dispatch(
        "exportReportTransactionList",
        this.filterExport
      );
      const data = this.$store.state.report.stateExportReportTransactionList;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `ReportTransaction-` + dateExcel + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.$refs.modalLoading.hide();
    },
    filterPage(value) {
      this.filter = value;
      this.getTransactionList();
    },
    handleChangeTake(value) {
      this.filter = value;
      this.getTransactionList();
    },
    submitFilter(value) {
      this.filter = value;
      if (this.filter.start_date) {
        this.filter.start_date = this.$moment(this.filter.start_date).format(
          "YYYY-MM-DD"
        );
      }
      if (this.filter.end_date) {
        this.filter.end_date = this.$moment(this.filter.end_date).format(
          "YYYY-MM-DD"
        );
      }
      this.getTransactionList();
    },
    async downLoadTemplate() {
      this.$refs.modalLoading.show();
      await this.$store.dispatch("downloadTemplateTransaction");
      const data = this.$store.state.importFile.downloadTransaction;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.$refs.modalLoading.hide();
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Transaction-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>
