<template>
  <b-sidebar
    id="SidebarFilterTransaction"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
      <b-input-group>
        <b-form-input
          type="search"
          class="search-bar"
          placeholder="Search Invoice Number"
          v-model="filter.search"
        >
        </b-form-input>
      </b-input-group>
      <div class="mt-3">
        <label class="font-weight-bold mb-2">Start Date - End Date</label>
        <div class="date-input">
          <span
            v-if="!filter.start_date"
            @click="$refs.dateStart.isOpen = true"
            class="w-40 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateStart.isOpen = true"
            class="w-50 pointer"
            >{{ filter.start_date | moment($formatDateNew) }}</span
          >
          <span class="px-2">-</span>
          <span
            v-if="!filter.end_date"
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >{{ filter.end_date | moment($formatDateNew) }}</span
          >
          <div :class="'icon-container text-right'">
            <font-awesome-icon
              icon="calendar-alt"
              :class="'cursor-default color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <datetime
          ref="dateStart"
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          v-model="filter.start_date"
          :max-datetime="filter.end_date || dateFormat()"
        >
        </datetime>
        <datetime
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          :max-datetime="dateFormat()"
          :min-datetime="filter.start_date"
          ref="dateEnd"
          v-model="filter.end_date"
        >
        </datetime>
      </div>
      <div class="mt-3">
        <InputSelect
          title="Branch"
          name="branch"
          :options="branch"
          :value="filter.branch_id"
          valueField="id"
          textField="name"
          :noPleaseSelect="true"
          @onDataChange="handleChangeType"
        />
      </div>
      <div class="mt-3">
        <b-form-group>
          <label class="font-weight-bold mb-2">Source name</label><br />
          <b-form-checkbox v-model="allSelected" @change="toggleAll">
            All
          </b-form-checkbox>
          <b-form-checkbox-group
            id="sale_chanel"
            v-model="selected"
            :options="saleChanel"
            value-field="id"
            text-field="name"
            name="sale_chanel"
            class="ml-4"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="mt-3">
        <b-form-group>
          <label class="font-weight-bold mb-2">Status</label><br />
          <b-form-checkbox
            v-model="allSelectedStatus"
            @change="toggleAllStatus"
          >
            Select All
          </b-form-checkbox>
          <b-form-checkbox-group
            id="status"
            v-model="selectedStatus"
            :options="statusFilter"
            value-field="id"
            text-field="name"
            name="status"
            class="ml-4"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    branch: {
      required: true,
      type: Array,
    },
    saleChanel: {
      required: true,
      type: Array,
    },
    statusFilter: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      selected: [],
      allSelected: false,
      allSelectedStatus: false,
      selectedStatus: [],
    };
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelected = false;
      } else if (newValue.length === this.saleChanel.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    selectedStatus(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelectedStatus = false;
      } else if (newValue.length === this.statusFilter.length) {
        this.allSelectedStatus = true;
      } else {
        this.allSelectedStatus = false;
      }
    },
  },
  methods: {
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    handleChangeType(value) {
      this.filter.branch_id = value;
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    toggleAll(checked) {
      if (checked) {
        this.selected = [2, 1];
      } else {
        this.selected = [];
      }
    },
    toggleAllStatus(checked) {
      if (checked) {
        this.selectedStatus = [1, 2, 4];
      } else {
        this.selectedStatus = [];
      }
    },
    clearFilter() {
      this.filter.search = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.selected = [];
      this.selectedStatus = [];
      this.allSelected = false;
      this.allSelectedStatus = false;
      this.filter.branch_id = 0;
      this.filter.source_id = null;
      this.filter.status_id = null;
    },
    submitAction() {
      this.filter.page = 1;
      this.filter.source_id = this.selected;
      this.filter.status_id = this.selectedStatus;
      this.$emit("submitFilter", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.w-40 {
  width: 40%;
}

.form-group {
  margin-bottom: 0.5rem;
}
</style>
