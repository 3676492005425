<template>
  <div>
    <b-row class="no-gutters">
      <b-col md="4" sm="3" lg="6" class="d-flex align-items-center text-left">
        <h4 class="mr-sm-4 header-tablepage">
          {{ title }}
        </h4>
      </b-col>
      <b-col md="8" sm="9" lg="6" class="text-right mt-2 mt-sm-0">
        <b-input-group>
          <b-form-input
            class="search-bar"
            @keyup.enter="handleSearch"
            :placeholder="placeholder"
            v-model="filter.search"
            v-debounce:200ms="autoSearch"
            :disabled="tab === 3 ? true : false"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack
              font-scale="2"
              type="submit"
              @click="handleSearch"
              :disabled="tab === 3 ? true : false"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <b-button
            @click.prevent="sidebarFilter"
            class="btn-filter ml-2"
            v-if="btnFilter"
            :disabled="tab === 3 ? true : false"
          >
            <font-awesome-icon icon="filter" class="d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="filter" class="pointer" />

              <span class="btn-text">Filter</span>
            </span>
          </b-button>
          <b-dropdown right class="btn-filter ml-2" v-if="btnExport">
            <template #button-content>
              <span class="d-none d-md-inline-flex btn-text mr-1">Action</span>
            </template>
            <b-dropdown-item @click="toPath('/importdata')"
              >Import Data (.xlsx)</b-dropdown-item
            >
            <b-dropdown-item @click="$emit('downLoadTemplate')"
              >Download Template</b-dropdown-item
            >
            <b-dropdown-item @click="toPath('/configdata')"
              >Import Config</b-dropdown-item
            >
            <b-dropdown-item @click="exportExcel"> Export</b-dropdown-item>
          </b-dropdown>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    placeholder: {
      required: true,
      type: String,
    },
    btnFilter: {
      required: false,
      type: Boolean,
    },
    btnExport: {
      required: false,
      type: Boolean,
    },
    tab: {
      required: false,
      type: Number,
    },
    importPath: {
      required: false,
    },
  },
  methods: {
    async handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          this.$emit("search", this.filter);
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        this.$emit("search", this.filter);
      }
    },
    autoSearch() {
      this.filter.page = 1;
      this.$emit("search", this.filter);
    },
    exportExcel() {
      this.$emit("confirmExport");
    },
    sidebarFilter() {
      this.$emit("sidebar");
    },
    toPath(path) {
      this.$router.push(this.importPath + path);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
</style>
